import React, { useState } from 'react'
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Table
} from 'reactstrap'
import { Filters, ListItem } from '.'
import { api, storage } from 'lib'
import { IgtLoader } from 'Atoms'

const CostCentersList = ({ onChangeFilter, modelList, isLoading }) => {
  const [modal, setModal] = useState(false);
  const user_email = storage.get('userEmail');
  const toggleInventoryModal = () => setModal(!modal);
  const [email, setEmail] = useState(user_email) // who do we send the report to
  
  const onSendReport = () => {
    let data = {
      cost_center: {
        email: true,
        send_to: email
      }
    }
    console.log("Submitted")
    console.log(email)
    api.post("/cost_centers/send_report", data)
      .then(resp => {
        alert("Your report will be emailed!");
      })
      .catch(err => {
        alert("There was a problem submitting this cost center. Please try again.")
        console.error("API Error: ", err)
      })
    toggleInventoryModal()
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={{ size: 6, offset: 3 }}>
          <h1 className="text-center">Cost Centers Table</h1>
        </Col>
        <Col lg="3">
          <Button href="/auth/create/cost_centers">Add New Cost Center</Button>{' '}
          <Button onClick={toggleInventoryModal}>Send Inventory Report</Button>
          {sendInventoryModal(modal, toggleInventoryModal, onSendReport, setEmail, email)}
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          {
            isLoading ?
              <IgtLoader isLoading={isLoading} />
              :
              <Table hover>
                <thead className='sticky-header'>
                  <tr>
                    <th>Identifier</th>
                    <th>Company Code</th>
                    <th>AWS GL</th>
                    <th>GCP GL</th>
                    <th>Azure GL</th>
                    <th>Owner</th>
                    <th>Approved(True/False) (True/False)</th>
                    <th>Owner Terminated (True/False)</th>
                    <th>Business Unit</th>
                    <th>Department</th>
                    <th>Active</th>
                    <th>Expired</th>
                    <th>Replacement</th>
                    <th>Backup</th>
                    <th>Created</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  
                <Filters
                  onChangeFilter={onChangeFilter}
                />
                  {
                    modelList && modelList.map((costCenter, i) => {
                      return (
                        <ListItem
                          modelListItem={costCenter}
                          key={i}
                          index={i}
                        />
                      )
                    })
                  }
                </tbody>
              </Table>
          }
        </Col>
      </Row>
    </Container>
  )
}

export default CostCentersList

function sendInventoryModal(modal, toggle, onSubmit, setEmail, email) {
  return <Modal isOpen={modal} toggle={toggle}>
    <ModalHeader toggle={toggle}>Email Excel Report</ModalHeader>
    <ModalBody>
      Would you like to generate a new copy of `CCOE-Cloud-Inventory.xlsx`?
      <InputGroup>
        <InputGroupText>Email</InputGroupText>
        <Input value={email} onChange={event => setEmail(event.target.value)} />
      </InputGroup>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onSubmit}>Send Report</Button>{' '}
      <Button color="secondary" onClick={toggle}>Cancel</Button>
    </ModalFooter>
  </Modal>
}
